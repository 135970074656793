import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import WaveSVGTop from "../components/WaveSVGTop"
import AltPageBackground from "../components/AltPageBackground"

const ShowcasePage: React.FC = () => {
  const imageData = useStaticQuery(graphql`
    query {
      brian: file(relativePath: { eq: "brian.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout
      title="Our Team"
      description="Learn more about the builders of Wavefoundry"
    >
      <Navbar />
      <div className="body-container">
        <div className="alt-page-header">
          <AltPageBackground />
          <h1>our team</h1>
          <div className="wave-svg-container">
            <WaveSVGTop />
          </div>
        </div>
        <div className="layout-container">
          <div className="alt-page-container our-team-container">
            <h6 className="text-center">
              Learn more about the creators at Wavefoundry
            </h6>
            <div className="grid-container margin-top-4 add-width-2">
              <div className="grid-item-2 padding-2">
                <div className="image-container">
                  <GatsbyImage fluid={imageData.brian.childImageSharp.fluid} />
                </div>
              </div>
              <div className="grid-item-2 padding-2">
                <h4>Brian Yates</h4>
                <p className="job-title">Software Developer + Founder</p>
                <p>
                  Hey, I'm Brian. I'm a Pittsburgh native who moved to
                  Charlotte, NC in 2014. I have a degree in industrial
                  engineering from Penn State (We Are!) and have been working in
                  the tech space for over 8 years, most recently as a software
                  engineer. I kinda stumbled into programming after graduating
                  college and have been addicted to it ever since. I love
                  designing and building websites for people - there's something
                  deeply satisfying about solving business problems and making
                  people's lives easier with software. That passion is what made
                  me decide to start Wavefoundry in 2020 - I want to help people
                  by solving their most critical business challenges, and by building cool stuff! If we
                  aren't already working together, I truly look forward to
                  working with you.
                </p>
              </div>
            </div>
            <div className="text-center margin-top-4">
              <Link
                to="/#contact"
                className="btn primary-btn full-width-mobile"
              >
                CONTACT US TODAY
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ShowcasePage
